// from js/modules/hero/category-hero/style.scss

// shared hero styles
.hero {
    &--no-image {
        .category-hero__content--heading {
            color: $black;
        }

        .category-hero__scaler-inner {
            padding-top: 0;
        }

        .category-hero__bg-overlay,
        .category-hero__bg-white-block,
        .category-hero__bg {
            display: none;
        }

        + .content__wrapper {
            margin-top: 0;
        }

        + .content__listing {
            margin-top: 0;
        }
    }

    &.is-fullscreen {
        z-index: 400;
    }
}
